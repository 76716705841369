import { clsx } from "clsx";
import { Link } from "@/components/Link/Link.component";
type SectionLinkProps = {
  readonly className?: string;
  readonly backgroundColor?: "primary" | "secondary";
  readonly children: string | JSX.Element;
  readonly targetUrl?: string | null;
  readonly position?: string;
};
export function SectionLink({
  className,
  children,
  targetUrl = null,
  position
}: SectionLinkProps) {
  return <div className={clsx(className, "max-w-full select-none text-xs font-bold uppercase")} data-sentry-component="SectionLink" data-sentry-source-file="SectionLink.component.tsx">
      {targetUrl !== null ? <Link className="w-fit hover:underline" href={targetUrl} position={position ?? "s-a"}>
          {children}
        </Link> : children}
    </div>;
}