import { clsx } from "clsx";
import type { ReactNode } from "react";
import { Link } from "@/components/Link/Link.component";
type BadgeProps = {
  readonly className?: string;
  readonly type?: "primary" | "outline";
  readonly children: string | ReactNode;
  readonly targetUrl?: string | null;
};
export function Badge({
  className,
  children,
  targetUrl = null,
  type = "primary"
}: BadgeProps) {
  return <div className={clsx(className, "w-fit select-none border text-xs font-theme-bold uppercase", type === "primary" && "border-transparent bg-primary text-white", type === "outline" && "border-primary bg-white text-primary")} data-sentry-component="Badge" data-sentry-source-file="Badge.component.tsx">
      {targetUrl !== null ? <Link className={clsx("block px-1.5 py-1 transition-colors", type === "primary" && "hover:bg-primary-hover", type === "outline" && "hover:bg-primary-hover hover:text-white")} href={targetUrl}>
          {children}
        </Link> : <span className="block px-1.5 py-1">{children}</span>}
    </div>;
}