import { clsx } from "clsx";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { TallFocusArticleTeaser } from "@/components/ArticleTeaser/FocusArticleTeaser/TallFocusArticleTeaser.component";
import { ArticleTeaserHeading } from "@/components/ArticleTeaserHeading/ArticleTeaserHeading.component";
import { HtmlContent } from "@/components/HtmlContent/HtmlContent.component";
import { ArticleTeaserImage } from "@/components/images/ArticleTeaserImage/ArticleTeaserImage.component";
import { ShortPublicationDateTime } from "@/components/PublicationDateTime/components/ShortPublicationDateTime/ShortPublicationDateTime.component";
import { RequiresSubscriptionLabel } from "@/components/RequiresSubscriptionLabel/RequiresSubscriptionLabel.component";
import { SectionLink } from "@/components/SectionLink/SectionLink.component";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import { Translation } from "@/components/Translation/Translation.component";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
type TallArticleTeaserProps = {
  readonly article?: ArticleTeaserModel;
  readonly className?: string;
  readonly hasImagePriority?: boolean;
  readonly imageSourceSetSize?: string;
  readonly imageVisibility?: "hidden";
  readonly leadVisibility?: "hidden" | "lg:block" | "xl:block";
  readonly trackingId?: string | null;
  readonly position?: string;
  readonly isRecommendation?: boolean;
};
export function TallArticleTeaser({
  className,
  article,
  hasImagePriority,
  imageSourceSetSize = `(max-width: ${tailwindScreenSize.md}) 40vw, 300px`,
  imageVisibility,
  leadVisibility = "lg:block",
  trackingId,
  position,
  isRecommendation = false
}: TallArticleTeaserProps) {
  if (!article) {
    return null;
  }
  const {
    content: {
      section,
      isFeature,
      publishDate,
      headline,
      lead,
      supportingImage,
      targetUrl,
      id
    },
    requiresSubscription
  } = article;
  const sectionObject = {
    name: isRecommendation ? {
      da: "Fik du læst?",
      de: "Haben Sie gelesen?",
      en: "Did you read?",
      no: "Fikk du lest?",
      sv: "Läste du?"
    } : section?.name,
    url: isRecommendation ? null : section?.targetUrl
  };
  return isFeature ? <TallFocusArticleTeaser article={article} hasImagePriority={hasImagePriority} imageVisibility={imageVisibility} leadVisibility={leadVisibility} trackingId={trackingId} /> : <article className={clsx(className, "h-full pb-2 text-black lg:pb-3")} data-k5a-pos={position}>
      <div className="grid grid-cols-5 gap-x-2 lg:grid-cols-1 lg:gap-x-0 lg:gap-y-2">
        {supportingImage !== null ? <div className={clsx(imageVisibility === "hidden" && "lg:hidden", "relative col-span-2 row-span-full aspect-4/3 lg:col-span-full lg:row-span-1")}>
            <ArticleTeaserImage alternativeText={supportingImage.alternativeText} hasImagePriority={hasImagePriority} sourceSetSize={imageSourceSetSize} src={supportingImage.aspectRatio4By3} targetUrl={targetUrl} />
          </div> : null}

        <div className="col-span-3 lg:col-span-full">
          {sectionObject.name !== undefined ? <div className="hidden divide-x divide-silver lg:mb-1 lg:flex">
              <SectionLink className={clsx("pr-[0.5ch]", isRecommendation && "text-primary")} targetUrl={sectionObject.url}>
                <Translation model={sectionObject.name} />
              </SectionLink>

              {isRecommendation ? <span className="select-none pl-[0.5ch] text-xs uppercase text-nobel">
                  <Translation model={{
              da: "Fra",
              de: "Von",
              en: "From",
              no: "Fra",
              sv: "Från"
            }} />{" "}
                  <ShortPublicationDateTime zonedDateTime={publishDate} />
                </span> : null}
            </div> : null}

          <SnowplowLink className="block hover:underline" href={targetUrl} testId={requiresSubscription ? "locked-article-teaser" : ""} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }}>
            <ArticleTeaserHeading className="text-xl font-theme-bold lg:text-2xl" title={headline} />
          </SnowplowLink>

          {leadVisibility !== "hidden" && lead !== null ? <SnowplowLink className={leadVisibility} href={targetUrl} trackingModel={{
          articleId: id,
          trackingId: trackingId ?? null,
          url: targetUrl
        }}>
              <HtmlContent className={clsx(leadVisibility, "hidden leading-snug transition-colors duration-150 hover:text-black lg:mt-2")} html={lead.html} />
            </SnowplowLink> : null}

          {requiresSubscription ? <RequiresSubscriptionLabel className="mt-2 text-primary lg:mt-3" /> : null}
        </div>
      </div>
    </article>;
}